<template>
  <div class="client-info box">
    <div class="columns">
      <div class="column">
        <h6>Телефон:</h6>
        <p>{{ clientData.phone }}</p>
      </div>
      <div class="column">
        <h6>ИНН:</h6>
        <p>{{ clientData.inn }}</p>
      </div>
      <div class="column">
        <h6>Наименование организации:</h6>
        <p>{{ clientData.orgName }}</p>
      </div>
    </div>
    <GroupButtons :id="clientData.id" :type="'entity'" />
  </div>
</template>

<script>
import GroupButtons from './common/GroupButtons';

export default {
  name: 'Entity',
  props: ['clientData'],
  components: {
    GroupButtons,
  },
};
</script>
